import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import { getCookies, setCookies } from "helper/cookies";


const useInactivityLogout = () => {
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      setCookies('is-active-on-other-platform', true);
      timeoutId = setTimeout(() => {
        setLoading(true);
        const isActiveOnOtherPlatform = getCookies('is-active-on-other-platform_avc') === true;

        if (isActiveOnOtherPlatform) {
          setLoading(false);
          resetTimer();
          setCookies("is-active-on-other-platform", false);
          return
        }

        // logout();
        setLoading(false);
      }, Number((process.env.REACT_APP_INACTIVITY_TIMEOUT)  || 2) * 60 * 60 * 1000);
    };

    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, [logout]);

  return {
    loading
  };
};

export default useInactivityLogout;
